import { render, staticRenderFns } from "./NavigationCittadino.vue?vue&type=template&id=5210302d&scoped=true"
import script from "./NavigationCittadino.vue?vue&type=script&lang=js"
export * from "./NavigationCittadino.vue?vue&type=script&lang=js"
import style0 from "./NavigationCittadino.vue?vue&type=style&index=0&id=5210302d&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5210302d",
  null
  
)

export default component.exports