import config from "../_config/config";
import { authHeader, postHeader } from "../_helpers";
import axios from "axios";

axios.defaults.baseURL = config.apiUrl;
axios.defaults.headers.post["Content-Type"] = "application/json";
axios.defaults.headers.post["X-Requested-With"] = "XMLHttpRequest";
axios.defaults.headers.put["Content-Type"] = "application/json";
axios.defaults.headers.put["X-Requested-With"] = "XMLHttpRequest";

export const adminService = {
  massiveReport,
  appData,
  getServices,
  getPrestazioni,
  enableActivityFor,
  enableSurveyFor,
  updateActivityStatus,
  updateSurveyStatus,
  getAllFdservices,
  enableFdservice,
  downloadSelectedCompany,
  getActivityLog,
  getAbilitateForActivity,
  getActivityById,
  getFilledSurveysForActivity,
  reportActivityPdf,
  reportActivityXls,
  saveNewCompany,
  assignProjectToCompany,
  getContattiPubblici,
  getExcelContattiPubblici,
  getExcelRegistratiOggi,
  getExcelRegistratiCompleto,
  addNewExam,
  requestUserSubscription,
  getAllExams,
  updateExam,
  deleteExam,
  getRegisteredUsers,
  getRegisteredUserExcel,
  getAvailableSubscriptions,
  generaVoucher,
  getAvailableSubscriptionVouchers,
  deleteVoucher,
  updateVoucher,
  updateVoucherStatus,
  updateService,
};

function massiveReport(data) {
  const sett = { headers: authHeader(), responseType: "blob" };
  return axios
    .post("companies/massive/report", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function appData(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("utilities/dati/app", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function getServices() {
  const sett = { headers: authHeader() };
  return axios
    .get("services/", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function getPrestazioni() {
  const sett = { headers: authHeader() };
  return axios
    .get("healthservices/", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function enableActivityFor(data) {
  const sett = { headers: authHeader() };
  var endpoint = "activities/enable/" + data.hash;
  return axios
    .post(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function enableSurveyFor(data) {
  const sett = { headers: authHeader() };
  var endpoint = "activities/surveys/enable/" + data.hash;
  return axios
    .post(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateActivityStatus(data) {
  const sett = { headers: authHeader() };
  var endpoint = "activities/status/update/" + data.hash;
  return axios
    .put(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateSurveyStatus(data) {
  const sett = { headers: authHeader() };
  var endpoint = "activities/surveys/status/update/" + data.hash;
  return axios
    .put(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getAllFdservices() {
  const sett = { headers: authHeader() };
  return axios
    .get("fdsservices/", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function enableFdservice(data) {
  const sett = { headers: authHeader() };
  var endpoint = "fdsservices/fdservice/adesioni/set/" + data.hashid;
  return axios
    .post(endpoint, data.form, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
function downloadSelectedCompany(filters) {
  const sett = { headers: authHeader(), params: filters, responseType: "blob" };
  return axios
    .get("companies", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getActivityLog(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("utilities/dati/logs", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getAbilitateForActivity(data) {
  const sett = { headers: authHeader(), params: data.filters };
  var endpoint = "activities/abilitate/list/" + data.hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getActivityById(hash) {
  const sett = { headers: authHeader() };
  var endpoint = "activities/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getFilledSurveysForActivity(data) {
  const sett = { headers: authHeader(), params: data.filters };
  var endpoint = "activities/surveys/filled/activity/" + data.hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function reportActivityPdf(hash, filters) {
  const sett = { headers: authHeader(), responseType: "blob", params: filters };
  var endpoint = "activities/report/filled/pdf/activity/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function reportActivityXls(hash, filters) {
  const sett = { headers: authHeader(), responseType: "blob", params: filters };
  var endpoint = "activities/report/filled/xls/activity/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function saveNewCompany(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("companies/new/register", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function assignProjectToCompany(hash, data) {
  const sett = { headers: authHeader() };
  const endpoint = "companies/" + hash + "/project";
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getContattiPubblici(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("users/contatti/pubblici", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getExcelContattiPubblici() {
  const sett = { headers: authHeader(), responseType: "blob" };
  return axios
    .get("users/contatti/pubblici/export/xls", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getExcelRegistratiOggi() {
  const sett = { headers: authHeader(), responseType: "blob" };
  return axios
    .get("users/registrati/oggi/export/xls", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getExcelRegistratiCompleto() {
  const sett = { headers: authHeader(), responseType: "blob" };
  return axios
    .get("users/registrati/full/export/xls", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function addNewExam(data) {
  const sett = { headers: authHeader() };
  return axios
    .post("exams", data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function requestUserSubscription(hash, filters) {
  const sett = { headers: authHeader(), params: filters };
  const endpoint = "users/subscriptions/request/free/" + hash;
  return axios
    .get(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getAllExams(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("exams", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateExam(hashid, data) {
  const sett = { headers: authHeader() };
  var endpoint = "exams/" + hashid;
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteExam(hashid) {
  const sett = { headers: authHeader() };
  var endpoint = "exams/" + hashid;
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getRegisteredUsers(filters) {
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("users/registrati", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getRegisteredUserExcel(filters) {
  const sett = { headers: authHeader(), responseType: "blob", params: filters };
  return axios
    .get("users/registrati/export/xls", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getAvailableSubscriptions() {
  const filters = { client: 2 };
  const sett = { headers: authHeader(), params: filters };
  return axios
    .get("subscriptions", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function getAvailableSubscriptionVouchers() {
  const sett = { headers: authHeader() };
  return axios
    .get("subscriptions/vauchers/all", sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function generaVoucher(data, hashid) {
  const sett = { headers: authHeader() };
  const endpoint = "subscriptions/" + hashid + "/vaucher";
  return axios
    .post(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function deleteVoucher(hashid) {
  const sett = { headers: authHeader() };
  var endpoint = "subscriptions/vaucher/" + hashid + "/delete";
  return axios
    .delete(endpoint, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateVoucher(hashid, data) {
  const sett = { headers: authHeader() };
  var endpoint = "subscriptions/vaucher/" + hashid + "/update";
  return axios
    .put(endpoint, data, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateVoucherStatus(hashid, data) {
  const sett = { headers: authHeader() };
  var endpoint = "subscriptions/vaucher/" + hashid + "/status";
  return axios
    .patch(endpoint, { enabled: data }, sett)
    .then((response) => {
      return Promise.resolve(response);
    })
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}

function updateService(id, data) {
  const sett = { headers: authHeader() };
  return axios
    .put(`services/${id}`, data, sett)
    .then((response) => response.data)
    .catch((err) => {
      var error = err.response;
      return Promise.reject(error);
    });
}
